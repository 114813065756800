import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { colors, analytics } from 'midtype-backend';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Layout from '../layout/Layout';
import Intro from '../components/customers/Intro';

import CompanyBackground from '../components/customers/CompanyBackground';
import Quote from '../components/customers/Quote';
import Screenshots from '../components/customers/Screenshots';

const Styled = styled.div`
  margin-bottom: 2rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${colors.GRAY_3()};
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  h1 {
    font-size: 2.5rem;
    line-height: initial;
    margin-top: 3.5rem;
  }
  h2 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-top: 3rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  h4 {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  p,
  li {
    color: ${colors.GRAY_3()};
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.8rem;
    margin-bottom: 1rem;
  }
  code,
  pre {
    font-size: 13px;
  }
  pre {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.03);
    margin: 2rem 0;
  }
  pre code {
    background: none !important;
  }
  strong {
    font-weight: 500;
  }
  li {
    margin-bottom: 0;
  }
  blockquote {
    margin: 0;
    margin-bottom: 2rem;
    border-bottom: 1px solid ${colors.GRAY_2()};
  }
  blockquote p {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  figcaption {
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 500;
    color: ${colors.GRAY_3()};
    text-align: center;
    margin-top: 2rem;
  }
  ul,
  ol {
    display: block;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  ul li {
    list-style: disc;
  }
  ol li,
  ul li {
    margin-left: 0;
    padding-left: 0.5rem;
  }
  img {
    max-width: 100%;
  }
  .content {
    width: 100%;
    margin: auto;
    max-width: 50rem;
    padding: 0 3rem;
  }
  @media screen and (max-width: 800px) {
    h1 {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    h2 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.75rem;
      line-height: 2rem;
    }
    h4,
    h5 {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    blockquote p {
      font-size: 2rem;
      line-height: 3rem;
    }
    p,
    li {
      font-size: 1.75rem;
      line-height: 2.5rem;
      margin-bottom: 2rem;
    }
    .content {
      padding: 0 2rem;
    }
  }
`;

const CustomerStoryLayout: React.FC<any> = props => {
  const { title, description, logo, image, color } = props.data.mdx.frontmatter;

  useEffect(() => {
    analytics.track('Read Customer Story', { title });
  }, []);

  const imagePath = props.data.images.edges[0]
    ? props.data.images.edges[0].node.publicURL
    : '';
  return (
    <Layout
      pageTitle={title}
      pageDescription={description}
      hideLines={true}
      image={process.env.GATSBY_MIDTYPE_MARKETING_URL + imagePath}
    >
      <Styled>
        <Intro
          title={title}
          description={description}
          logo={logo}
          image={image}
          background={color}
        />
        <div className="content">
          <MDXProvider components={{ CompanyBackground, Quote, Screenshots }}>
            <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </Styled>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $image: String) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        title
        description
        logo
        image
        color
        learn_more
      }
    }
    images: allFile(filter: { relativePath: { eq: $image } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

export default CustomerStoryLayout;
