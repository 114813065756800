import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

import { MAX_WIDTH } from './Intro';

const Styled = styled.div`
  width: 100vw;
  background: ${colors.GRAY_2(0.5)};
  padding: 3rem;
  margin-bottom: 5rem;
  margin-left: calc(((100vw - 50rem) / -2) - 3rem);

  .container {
    width: 100%;
    margin: auto;
    max-width: ${MAX_WIDTH};
    padding: 0 3rem;
  }
  p {
    margin-top: 1rem;
  }
  @media screen and (max-width: 800px) {
    margin-left: calc(((100vw - 50rem) / -2) - 2rem);
    p {
      font-size: 1.2rem !important;
      line-height: 1.8rem !important;
    }
    .container {
      padding: 0;
    }
  }
  @media screen and (max-width: 640px) {
    margin-left: -2rem;
  }
`;

const CompanyBackground: React.FC<{ url: string }> = props => {
  return (
    <Styled>
      <div className="container">
        <label className="text-caps text-small">Company</label>
        <p>{props.children}</p>
        <a href={props.url} target="_blank">
          {props.url}
        </a>
      </div>
    </Styled>
  );
};

export default CompanyBackground;
