import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

import Image from '../Image';

const StyledQuote = styled.div`
  width: 100%;
  margin: 3rem 0;
  border-left: 3px solid ${colors.PURPLE_DARK()};
  padding: 0 2rem;
  .quote {
    color: ${colors.PURPLE_DARK()};
    margin: 0;
    line-height: 2.2rem;
    font-size: 1.5rem;
    font-weight: 500;
    font-style: italic;
  }
  .person {
    margin-top: 2rem;
    display: flex;
  }
  .person__photo {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }
  .person__photo .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  .person h4 {
    margin: 0;
  }
`;

export const Quote: React.FC<{
  name?: string;
  title?: string;
  photo?: string;
}> = props => {
  const { name, title, photo } = props;
  return (
    <StyledQuote>
      <p className="quote">{props.children}</p>
      {name && title && photo && (
        <div className="person">
          <div className="person__photo">
            <Image src={photo} />
          </div>
          <div className="person__info">
            <h4>{name}</h4>
            <label className="text-caps text-small text-gray">{title}</label>
          </div>
        </div>
      )}
    </StyledQuote>
  );
};

export default Quote;
