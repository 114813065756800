import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

import Image from '../Image';

interface IIntroProps {
  title: string;
  background: string;
  description: string;
  image: string;
  logo: string;
}

export const MAX_WIDTH = '100rem';

const StyledIntro = styled.div`
  width: 100%;
  margin: auto;
  max-width: ${MAX_WIDTH};
  padding-top: 7rem;
  position: relative;

  .title {
    width: 50%;
    padding: 3rem;
  }
  .title__label {
    display: flex;
    margin-bottom: 4rem;
    align-items: center;
  }
  .title__label label {
    color: ${colors.WHITE(0.6)};
  }
  .title__label__logo {
    width: 5rem;
    margin-left: 2rem;
  }
  .title__heading {
    color: ${colors.WHITE()};
  }
  .title__customer-name {
    color: ${colors.WHITE()};
  }
  .photo {
    position: absolute;
    right: 0;
    top: 0;
    width: 60%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  .photo .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    padding-top: 0;
    .title {
      width: 100%;
      padding: 2rem;
    }
    .photo {
      position: relative;
      width: 100%;
      height: 20rem;
    }
  }
`;

const Intro: React.FC<IIntroProps> = props => {
  const { background, image, logo, title, description } = props;
  return (
    <StyledIntro>
      <div className="photo">
        <Image src={image} />
      </div>
      <div className="title" style={{ background }}>
        <div className="title__label">
          <label className="text-caps text-small">Customer Story</label>
          <div className="title__label__logo">
            <Image src={logo} />
          </div>
        </div>
        <h1 className="title__heading">{title}</h1>
        <h4 className="title__customer-name">{description}</h4>
      </div>
    </StyledIntro>
  );
};

export default Intro;
