import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'midtype-backend';

import Image from '../Image';

const StyledScreenshots = styled.div`
  width: 100vw;
  background: ${colors.GRAY_2(0.5)};
  padding: 3rem;
  margin: 3rem 0;
  margin-left: calc(((100vw - 50rem) / -2) - 3rem);

  .screenshots {
    width: 100%;
    display: grid;
    grid-gap: 2rem;
  }
  .screenshot {
    overflow: hidden;
    box-shadow: ${styles.BOX_SHADOW};
    will-change: transform;
    transition: 250ms all;
  }
  .screenshot:hover {
    transform: scale(1.1);
  }
  @media screen and (max-width: 1280px) {
    .screenshots {
      grid-template-columns: 1fr 1fr 1fr !important;
    }
  }
  @media screen and (max-width: 800px) {
    margin-left: calc(((100vw - 50rem) / -2) - 2rem);
    .screenshots {
      grid-template-columns: 1fr 1fr !important;
    }
  }
  @media screen and (max-width: 640px) {
    margin-left: -3rem;
    .screenshots {
      grid-template-columns: 1fr !important;
    }
  }
`;

const Screenshots: React.FC<{
  count: string;
  path: string;
  caption?: string;
}> = props => {
  const total = parseInt(props.count, 0) || 1;
  const shots = Array.from(Array(total).keys());
  return (
    <StyledScreenshots>
      <div
        className="screenshots"
        style={{ gridTemplateColumns: `repeat(${total}, 1fr)` }}
      >
        {shots.map((_shot, i) => (
          <div className="screenshot" key={i}>
            <Image src={props.path + `/screenshot-${i + 1}.png`} />
          </div>
        ))}
      </div>
      {props.caption && <figcaption>{props.caption}</figcaption>}
    </StyledScreenshots>
  );
};

export default Screenshots;
